import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

import holdingPeriod from "../images/2.0 Investment Criteria - Mezz/Holding Period.svg";
import industry from "../images/2.0 Investment Criteria - Mezz/Industry.svg";
import investment from "../images/2.0 Investment Criteria - Mezz/Investment.svg";
import location from "../images/2.0 Investment Criteria - Mezz/Location.svg";
import netIncome from "../images/2.0 Investment Criteria - Mezz/Net Income.svg";
import terms from "../images/2.0 Investment Criteria - Mezz/Terms.svg";
import typeOfFinancing from "../images/2.0 Investment Criteria - Mezz/Type of Financing.svg";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <Layout>
      <SearchEngineOptimization
        title="Assurance Mezzanine"
        description="Accelerate your company's growth with an investment from Assurance Mezzanine Fund. We offer up to $20M in customized growth solutions. Contact us to learn more."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden pt-14 pb-20 md:pt-22 md:pb-52">
        <div className="absolute top-0 left-0 h-full w-full">
          <StaticImage
            src="../images/2.0 Investment Criteria - Mezz/1.0 hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container relative">
          <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="text-center md:text-left">
              <h1 className="text-white">Assurance Mezzanine</h1>
              <p className="text-white/80 md:text-xl">
                Assurance Mezzanine Fund is a private debt fund providing $3 to
                $20 million of customized growth solutions to profitable,
                lower-middle-market companies.
              </p>
              <ButtonSolid modal="modal-contact" text="Contact" />
            </div>
            <div>
              <StaticImage
                src="../images/2.0 Investment Criteria - Mezz/1.1 hero.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section className="relative -mx-4 bg-primary-50 px-2 pb-20 md:mx-0 md:-mt-26 md:mb-32 md:px-14 md:pt-26 md:pb-32 lg:px-26">
          <div className="container">
            <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
              <ScrollNavigation>
                <li>
                  <AnchorLink
                    to={url + "#section-1"}
                    title="Criteria"
                    stripHash
                  />
                </li>
                <li>
                  <AnchorLink
                    to={url + "#section-2"}
                    title="Structure"
                    stripHash
                  />
                </li>
                <li>
                  <AnchorLink
                    to={url + "#section-3"}
                    title="Transactions"
                    stripHash
                  />
                </li>
              </ScrollNavigation>

              <div className="space-y-10 md:col-span-9 md:col-end-13 md:space-y-24">
                <div id="section-1" className="bg-white p-8 shadow-5xl md:p-14">
                  <header className="mb-10 md:mb-14">
                    <h2>Our Investment Criteria</h2>
                    <p>
                      We’re seeking experienced and committed management teams
                      and a company that has effective financial controls with a
                      history of profitability.
                    </p>
                  </header>

                  <div className="space-y-10">
                    <div>
                      <img src={location} className="mb-2.5" />
                      <h3 className="heading-six">Location</h3>
                      <p className="mb-0">
                        We’re looking to invest with private and public
                        companies headquartered within the United States.
                      </p>
                    </div>

                    <div>
                      <img src={netIncome} className="mb-2.5" />
                      <h3 className="heading-six">EBITDA</h3>
                      <p className="mb-0">
                        Your earnings before interest, taxes, depreciation, and
                        amortization (EBITDA) should be between $1 and $10
                        million.
                      </p>
                    </div>

                    <div>
                      <img src={industry} className="mb-2.5" />
                      <h3 className="heading-six">Industry</h3>
                      <p className="mb-0">
                        We invest in a variety of industries, with the exception
                        of real estate or oil and gas exploration (however, oil
                        and gas service companies are welcome).
                      </p>
                    </div>
                  </div>
                </div>

                <div id="section-2" className="bg-white p-8 shadow-5xl md:p-14">
                  <h2>Transactions</h2>
                  <p>We establish investment transactions that include:</p>
                  <ul className="styled-list-bullet">
                    <li>Growth Capital</li>
                    <li>Acquisition Financing</li>
                    <li>Management Buyouts</li>
                    <li>Transactions to Provide Shareholder Liquidity</li>
                    <li>Corporate Divestitures</li>
                    <li>Non-sponsored Transactions</li>
                    <li>Sponsored Led Transactions</li>
                  </ul>
                </div>

                <div id="section-3" className="bg-white p-8 shadow-5xl md:p-14">
                  <h2 className="mb-10 md:mb-14">Financing Structure</h2>

                  <div className="space-y-10">
                    <div>
                      <img src={investment} className="mb-2.5" />
                      <h3 className="heading-six">Investment</h3>
                      <p className="mb-0">
                        $3 million and $20 million of customized growth capital
                        per transaction, with the ability to syndicate larger
                        financing
                      </p>
                    </div>

                    <div>
                      <img src={typeOfFinancing} className="mb-2.5" />
                      <h3 className="heading-six">Type of Financing</h3>
                      <p className="mb-0">
                        Mezzanine debt or preferred equity investments
                      </p>
                    </div>

                    <div>
                      <img src={terms} className="mb-2.5" />
                      <h3 className="heading-six">Terms</h3>
                      <p className="mb-0">
                        Generally interest only for a period and then flexible
                        amortization
                      </p>
                    </div>

                    <div>
                      <img src={holdingPeriod} className="mb-2.5" />
                      <h3 className="heading-six">Holding Period</h3>
                      <p className="mb-0">
                        3 to 5 years expected holding period
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
